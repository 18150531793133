import React from "react";
import { ContentCopy } from "../../../components/content/ContentCopy";
import { Dropdown } from "../../../components/dropdowns/Dropdown";
import { useTranslation } from "react-i18next";

export interface IPurchaseInfoProps {
  order: string;
  iccid: string;
}

export const PurchaseInfoDropdown = ({
  iccid,
  order,
}: IPurchaseInfoProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Dropdown
      title={t(
        "global.customerV2.details.generalInformation.dropdowm.first.title"
      )}
      desc={
        <div className="flex flex-col gap-6 w-full">
          <h6 className="font-bold text-sm text-neutral-charcoal">
            {t(
              "global.customerV2.details.generalInformation.dropdowm.first.description"
            )}
          </h6>
          <div className="flex flex-col gap-[2px]">
            <ContentCopy
              bgContainer="bg-white"
              orientation="UP"
              textCopy={order}
            >
              <div className="flex flex-col gap-1">
                <h6 className="font-bold text-neutral-charcoal text-sm">
                  {t(
                    "global.customerV2.details.generalInformation.dropdowm.first.cards.one.title"
                  )}
                </h6>
                <p className="font-normal text-neutral-charcoal text-sm">
                  {order}
                </p>
              </div>
            </ContentCopy>
            <ContentCopy
              bgContainer="bg-white"
              orientation="DOWN"
              textCopy={iccid}
            >
              <div className="flex flex-col gap-1">
                <h6 className="font-bold text-neutral-charcoal text-sm">
                  {t(
                    "global.customerV2.details.generalInformation.dropdowm.first.cards.two.title"
                  )}
                </h6>
                <p className="font-normal text-neutral-charcoal text-sm">
                  {iccid}
                </p>
              </div>
            </ContentCopy>
          </div>
        </div>
      }
    />
  );
};
