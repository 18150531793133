import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiLogout, mdiWeb } from "@mdi/js";
import { HeaderItemProfile } from "../HeaderItemProfile";
import { BannerMobile } from "./BannerMobile";
import { ItemsMobile } from "../menuItem/ItemsMobile";
import HButton from "../../common/hbutton/HButton";
import { ItemNavInstructionsMobile } from "../menuItem/ItemNavInstructionsMobile";
import { useCustomNavigate } from "../../../hooks";
import { useCustomerStore } from "../../../store/customerStore";
import { ICustomerStore } from "../../../interfaces";
import { getCurrencyData } from "../../../utils/getCurrencyData";
import { downloadPlatformApp, removeToken } from "../../../utils";

export const MobileMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const {
    language,
    profile,
    currency,
    isOpenMenuMobile,
    toggleMenuMobile,
    toggleMenuLanguage,
  } = useCustomerStore((state: ICustomerStore) => state);

  const menuItems = [
    {
      id: 1,
      customElement: (
        <ItemsMobile
          title={t("global.customerV2.header.nav.item1")}
          path={`/`}
        />
      ),
    },
    {
      id: 2,
      customElement: <ItemNavInstructionsMobile />,
    },
    {
      id: 3,
      customElement: (
        <ItemsMobile
          title={t("global.customerV2.header.nav.item3")}
          path={`/helpCenter`}
        />
      ),
    },
    {
      id: 4,
      customElement: (
        <ItemsMobile
          title={t("global.customerV2.header.menu.item1")}
          path={`/account`}
        />
      ),
    },
    {
      id: 5,
      customElement: (
        <ItemsMobile
          isNewItem
          title={t("global.customerV2.travelClub.title")}
          path={`/referralBoard`}
        />
      ),
    },
    {
      id: 6,
      customElement: (
        <ItemsMobile title={"Purchase Receipts"} path={`/invoice`} offDivider />
      ),
    },
  ];

  const onLogout = () => {
    toggleMenuMobile(false);
    const prevEmail = localStorage.getItem("email");
    localStorage.clear();
    removeToken();
    localStorage.setItem("email", prevEmail || "");
    navigate("/login");
  };

  return (
    <div
      data-testid="mobile-menu"
      className="absolute ease-in duration-[3000] w-full md:max-w-[395px] p-5 items-center top-[72px] left-0 lg:hidden 
bg-white h-screen z-50 shadow-[0_20px_25px_-5px_rgb(0,0,0,0.3),_0_8px_10px_-6px_rgb(0,0,0,0.6)] overflow-scroll "
    >
      <div className="flex flex-col gap-10 w-full h-screen ">
        <div className="w-full flex justify-between items-center">
          <HeaderItemProfile name={profile?.firstName || ""} reverseContent />
          <div
            data-testid="btn_open_select_language_mobile"
            className="transition-all duration-1000 cursor-pointer flex gap-2 items-center justify-between border rounded-lg py-3 px-4"
            onClick={() => {
              toggleMenuLanguage(true);
              toggleMenuMobile(!isOpenMenuMobile);
            }}
          >
            <Icon color="#96969D" path={mdiWeb} title="Web" size={0.8} />
            <p className="text-sm text-neutral-charcoal font-medium">{`${language.toUpperCase()} - ${getCurrencyData(currency).navbarLabel
              }`}</p>
          </div>
        </div>

        <div className="flex flex-col">
          {menuItems.map((item) => {
            return <Fragment key={item.id}>{item.customElement}</Fragment>;
          })}
        </div>

        <BannerMobile
          onClick={downloadPlatformApp}
          textSide="left"
          text={t("global.customerV2.menu.banner.downloadApp")}
        />

        <HButton
          type="button"
          name="button logout"
          onClick={onLogout}
          icon={mdiLogout}
          text={t("global.customerV2.header.menu.item2")}
          className="w-full h-[48px] flex justify-center items-center gap-2 cursor-pointer font-medium"
        />
      </div>

      <div className="h-20"></div>
    </div>
  );
};
